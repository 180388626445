import React from "react";
import "./Services.css";
import { BiCheck } from "react-icons/bi";
const Services = () => {
  return (
    <div>
      <section id='services'>
        <h5>What I Offer</h5>
        <h2>Services</h2>

        <div className='container services__container'>
          <article className='service'>
            <div className='service__head'>
              <h3>Web Development</h3>
            </div>
            <ul className='service__list'>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Custom Website Development</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Responsive Web Design</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Frontend Development</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Backend Development</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>E-commerce Solutions</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Performance Optimization</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>SEO Optimization</p>
              </li>

              <li>
                <BiCheck className='service__list-icon' />
                <p>Maintenance & Support</p>
              </li>
            </ul>
          </article>
          {/* END of WEB DEVELOPMENT */}

          <article className='service'>
            <div className='service__head'>
              <h3>App Development</h3>
            </div>
            <ul className='service__list'>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Native App Development</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Cross-Platform App Development</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>UI/UX Design</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Backend Integration</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>API Integration</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>App Store Deployment</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Maintenance & Support</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>App Store Deployment</p>
              </li>
            </ul>
          </article>
          {/* End of Content Creation */}
          <article className='service'>
            <div className='service__head'>
              <h3>Content Creation</h3>
            </div>
            <ul className='service__list'>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Content Strategy</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Copywriting</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Content Marketing</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Content Editing</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>SEO Copywriting</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Social Media Content</p>
              </li>

              <li>
                <BiCheck className='service__list-icon' />
                <p>Email Marketing Content</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Performance Tracking</p>
              </li>
            </ul>
          </article>
        </div>
      </section>
    </div>
  );
};

export default Services;
