import React from "react";
import "./Experience.css";
import { BsPatchCheckFill } from "react-icons/bs";
const Experience = () => {
  return (
    <div>
      <section id='experience'>
        <h5>What Skills I Have</h5>
        <h2>My Experience</h2>
        <div className='container experience__container'>
          <div className='experience__frontend'>
            <h3>Front End Development</h3>
            <div className='experience__content'>
              <article className='experience__details'>
                <BsPatchCheckFill className='experience__details-icons' />
                <div>
                  <h4>HTML5 & CSS3</h4>
                  <small className='text-light'>Basic</small>
                </div>
              </article>

              <article className='experience__details'>
                <BsPatchCheckFill className='experience__details-icons' />
                <div>
                  <h4>JavaScript ES6</h4>
                  <small className='text-light'>Basic</small>
                </div>
              </article>

              <article className='experience__details'>
                <BsPatchCheckFill className='experience__details-icons' />
                <div>
                  <h4>React Native</h4>
                  <small className='text-light'>Basic</small>
                </div>
              </article>

              <article className='experience__details'>
                <BsPatchCheckFill className='experience__details-icons' />
                <div>
                  <h4>Redux</h4>
                  <small className='text-light'>Basic</small>
                </div>
              </article>

              <article className='experience__details'>
                <BsPatchCheckFill className='experience__details-icons' />
                <div>
                  <h4>UI/UX Design</h4>
                  <small className='text-light'>Basic</small>
                </div>
              </article>

              <article className='experience__details'>
                <BsPatchCheckFill className='experience__details-icons' />
                <div>
                  <h4>Responsive Design</h4>
                  <small className='text-light'>Basic</small>
                </div>
              </article>
            </div>
          </div>
          {/* End of Front End */}
          <div className='experience__backend'>
            <h3>Backend Development</h3>
            <div className='experience__content'>
              <article className='experience__details'>
                <BsPatchCheckFill className='experience__details-icons' />
                <div>
                  <h4>Node JS</h4>
                  <small className='text-light'>Basic</small>
                </div>
              </article>

              <article className='experience__details'>
                <BsPatchCheckFill className='experience__details-icons' />
                <div>
                  <h4>Express JS</h4>
                  <small className='text-light'>Basic</small>
                </div>
              </article>

              <article className='experience__details'>
                <BsPatchCheckFill className='experience__details-icons' />
                <div>
                  <h4>RESTful APIs</h4>
                  <small className='text-light'>Basic</small>
                </div>
              </article>

              <article className='experience__details'>
                <BsPatchCheckFill className='experience__details-icons' />
                <div>
                  <h4>Firebase DB</h4>
                  <small className='text-light'>Basic</small>
                </div>
              </article>

              <article className='experience__details'>
                <BsPatchCheckFill className='experience__details-icons' />
                <div>
                  <h4>Database Security</h4>
                  <small className='text-light'>Basic</small>
                </div>
              </article>

              <article className='experience__details'>
                <BsPatchCheckFill className='experience__details-icons' />
                <div>
                  <h4>GraphQL</h4>
                  <small className='text-light'>Basic</small>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Experience;
