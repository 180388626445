import React, { useState, useEffect } from "react";
import "./App.css";
import Header from "./components/header/Header";
import Nav from "./components/nav/Nav";
import About from "./components/about/About";
import Experience from "./components/experience/Experience";
import Services from "./components/services/Services";
import Portfolio from "./components/portfolio/Portfolio";
import Testimonials from "./components/testimonials/Testimonials";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate some async action like fetching data or loading resources
    setTimeout(() => {
      setLoading(false); // Set loading to false after the delay
    }, 1000); // Change the delay as per your requirement
  }, []);

  return (
    <>
      {loading ? (
        // Display professional spinner while app is loading
        <div className='loader-container'>
          <div className='loader'></div>
        </div>
      ) : (
        <>
          <Header />
          <Nav />
          <About />
          <Experience />
          <Services />
          <Portfolio />
          <Testimonials />
          <Contact />
          <Footer />
        </>
      )}
    </>
  );
};

export default App;
